<template>
	<div class="CompetitionsForTeachers _cover">
		<h2 class="CompetitionsForTeachers-title _cover-title">
			Конкурсы для педагогов
		</h2>

		<h3 class="Intelligence-block-two-titel _cover-title">
			Уважаемые коллеги!
		</h3>
		<p class="Intelligence-sub-title">
			Приглашаем вас принять участие в конкусе среди педагогических работников на право участия в реализации
			дополнительных общеразвивающих программ образовательного центра "Персей"
		</p>

		<p class="JuniorProfi-sub-title">
			Цель проведения конкурсного отбора:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				формирование кадрового резерва высококвалифицированных педагогов для
				работы с одаренными учащимися;
			</li>
			<li class="JuniorProfi-items">
				стимулирование профессионального развития и роста педагогов,
				работающих в сфере выявления и развития детской одаренности
			</li>
		</ul>

		<p class="Intelligence-sub-title">
			Конкурс проводится в заочной форме. Участники , набравшие наибольшее количество баллов, получат право осуществлять
			педагогическую деятельность по реализации дополнительных общеразвивающих программ в Образовательном центре
			«Персей»
		</p>

		<p class="Intelligence-sub-title">
			Ждем заявки в срок до 4 ноября 2024 года на адрес электронной почты <a style="color:blue"
				href="v.samodurova@perseusirk.ru">v.samodurova@perseusirk.ru</a>
		</p>

		<p class="Intelligence-sub-title">
			Ответственное лицо: Самодурова Вера Геннадьевна, руководитель Центра развития конкурсного движения и детских
			инициатив 8 (924) 711-78-35 <a style="color:blue" href="v.samodurova@perseusirk.ru">v.samodurova@perseusirk.ru</a>
		</p>

		<ul class="Documents-list">
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/09/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE-%D0%BA%D0%BE%D0%BD%D0%BA%D1%83%D1%80%D1%81%D0%B5-%D0%B4%D0%BB%D1%8F-%D0%9F%D0%B5%D0%B4%D0%B0%D0%B3%D0%BE%D0%B3%D0%BE%D0%B2.pdf"
					target="_blank">
					Положение о порядке и основании перевода, отчисления и восстановления учащихся по дополнительным
					общеразвивающим программам
				</a>
			</li>
		</ul>
	</div>
</template>