<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Беспилотные авиационные системы»
		</h2>
		<p class="EventsPage-date">
			с 26 сентября по 9 октября 2024
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=193" target="_blank">
			Moodle
		</a>
		<!-- <a class="EventsPage-btn temp-btn" href="" target="_blank">
			Навигатор
		</a> -->
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального
			проекта «Успех каждого ребёнка» проводит профильную смену «Беспилотные авиационные системы» для учащихся 8-11-х
			классов общеобразовательных организаций.

		</p>

		<p class="EventsPage-text">
			В рамках программы обучающиеся получат технические знания и умения, включающие изучение устройства и принципов
			работы беспилотных летательных аппаратов, навыки сборки и ремонта устройств, в том числе 3д печать отдельных
			деталей, научатся управлять беспилотными летательными аппаратами, а также изучат правила и отрабатывают спортивные
			навыки по авиамодельному спорту в классе моделей F9U.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Пашенцева Дарья Олеговна, авиационный техник факультета «Авиационных систем и комплексов» Иркутский филиал
				МГТУ ГА;
			</li>
			<li class="JuniorProfi-items">
				Устинов Ян Викторович, преподаватель цикловой комиссии беспилотных авиационных систем Иркутский филиал МГТУ
				ГА.
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Зарегистрироваться, заполнить анкету и выполнить задание до 16 сентября 2024 года на платформе Образовательного
				центра «Персей», системе Moodle.
			</li>
			<li class="JuniorProfi-items">
				Для тех участников, которые пройдут конкурсный отбор, необходимо записаться на программу «Беспилотные
				авиационные системы» на ресурсе «Навигатор дополнительного образования Иркутской области».
			</li>
			<li class="JuniorProfi-items">
				Профильная смена состоится с 26 сентября по 9 октября 2024 года на бесплатной основе в Образовательном центре
				«Персей» (Иркутская область, Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
			</li>
		</ul>
		<hr>

		<p class="EventsPage-text">
			<b>Результаты отбора будут размещены 17 сентября на сайте Образовательного центра «Персей».</b>
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Список учащихся, приглашенных на очную профильную смену:
		</p>
		<ol class="EventsPage-list-number">
			<li class="EventsPage-number">Абрамов Василий Иванович</li>
			<li class="EventsPage-number">Аджалова Джулай Витальевна</li>
			<li class="EventsPage-number">Аносова Екатерина Андреевна</li>
			<li class="EventsPage-number">Батракова Раиса Петровна</li>
			<li class="EventsPage-number">Бычко Степан Андреевич</li>
			<li class="EventsPage-number">Васьков Сергей Дмитриевич</li>
			<li class="EventsPage-number">Ветушева Кира Александровна</li>
			<li class="EventsPage-number">Глушкова Анастасия Александровна</li>
			<li class="EventsPage-number">Горшкова Екатерина Евгеньевна</li>
			<li class="EventsPage-number">Готовко Дарья Николаевна</li>
			<li class="EventsPage-number">Грязнов Глеб Денисович</li>
			<li class="EventsPage-number">Данилова Милана Григорьевна</li>
			<li class="EventsPage-number">Дерягина Дарья Александровна</li>
			<li class="EventsPage-number">Дубровин Дмитрий Владимирович</li>
			<li class="EventsPage-number">Иванова Дарья Сергеевна</li>
			<li class="EventsPage-number">Ивин Даниил Евгеньевич</li>
			<li class="EventsPage-number">Инкеева Полина Романовна</li>
			<li class="EventsPage-number">Казарина Дарья Анатольевна</li>
			<li class="EventsPage-number">Кобиляцкий Николай Сергеевич</li>
			<li class="EventsPage-number">Ковалёва Екатерина Михайловна</li>
			<li class="EventsPage-number">Крупанова Елизавета Витальевна</li>
			<li class="EventsPage-number">Кузнецова Анастасия Михайловна</li>
			<li class="EventsPage-number">Кулинченко Семён Николаевич</li>
			<li class="EventsPage-number">Мороз Дарья Владимировна</li>
			<li class="EventsPage-number">Нарицина Наталия Артемовна</li>
			<li class="EventsPage-number">Никифоров Иван Владимирович</li>
			<li class="EventsPage-number">Носков Александр Сергеевич</li>
			<li class="EventsPage-number">Палубисок Марина Владимировна</li>
			<li class="EventsPage-number">Петрашова Виктория Дмитриевна</li>
			<li class="EventsPage-number">Петрашов Михаил Алексеевич</li>
			<li class="EventsPage-number">Рожков Андрей Александрович</li>
			<li class="EventsPage-number">Руслан Перфильев Алексеевич</li>
			<li class="EventsPage-number">Силаев Сергей Сергеевич</li>
			<li class="EventsPage-number">Смирнов Герман Дмитриевич</li>
			<li class="EventsPage-number">Соколов Илья Дмитриевич</li>
			<li class="EventsPage-number">Травникова Валерия Романовна</li>
			<li class="EventsPage-number">Федосеева Кира Андреевна</li>
			<li class="EventsPage-number">Хабадаева Мария Викторовна</li>
			<li class="EventsPage-number">Шевцов Артемий Евгеньевич</li>
			<li class="EventsPage-number">Шерешков Алексей Андреевич</li>
		</ol>
		<br>
		<hr>

		<p class="JuniorProfi-sub-title">
			Резерв:
		</p>
		<ol class="EventsPage-list-number">
			<li class="EventsPage-number">Костина Карина Алексеевна</li>
			<li class="EventsPage-number">Шаповалов Даниил Владиславович</li>

		</ol>
		<br>
		<hr>

		<p class="EventsPage-text">
			Для участников смены будет проведено организационное собрание 18 сентября 2024 г. в 20.00, <a style="color:blue"
				href="https://pruffme.com/landing/Perseus/tmp1725265114">ссылка на подключение</a>
		</p>
		<hr>

		<!-- <p class="JuniorProfi-sub-title">
			Справки-вызовы
		</p>
		<a class="EventsPage-link" href="" target="_blank">
			Скачать
		</a>
		<hr> -->

		<!-- <p class="JuniorProfi-sub-title">
			Сертификаты
		</p>
		<a class="EventsPage-link" href="" target="_blank">
			Скачать
		</a>
		<hr> -->

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>