<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			17.09.2024
		</p>
		<h2 class="NewsPage-title">
				Более 70 школьников Приангарья стали участниками сентябрьских профильных смен в «Персее»
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage81.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В Региональном центре выявления и поддержки одарённых детей «Персей» прошли профильные смены «Математика»,
				«Астрономия» и «Байкальские звезды «Абилимпикса».
			</p>
			<p class="NewsPage-sub-title">
				На смене «Астрономия» школьники познакомились с основами ориентирования и определения времени по небесным
				объектам. Они научились пользоваться цифровыми инструментами и программами для астрономических наблюдений. Также
				участники смогли провести практические наблюдения за звездами и планетами с помощью телескопов.
			</p>
			<p class="NewsPage-sub-title">
				Участники профильной смены под руководством ведущих специалистов Большого Иркутского планетария, Иркутского
				государственного университета (ИГУ), Астрономической обсерватории ИГУ, Института солнечно-земной физики
				Сибирского отделения Российской академии наук и Автономной некоммерческой организации «Звездный десант»
				готовились к олимпиадам и научным конкурсам.
			</p>
			<p class="NewsPage-sub-title">
				– Одна из целей этой смены — поиск и отбор детей, которые готовы заниматься астрономией. Мы ищем тех, кого
				интересует эта наука и кто мог бы представлять Иркутскую область на всероссийской олимпиаде по астрономии.
				Астрономия увлекает детей, так как она способствует завершению формирования научного мировоззрения. Потребность
				в астрономах в Иркутске будет только расти, ведь Иркутская область — один из ключевых астрономических центров
				страны. Это направление активно развивается в регионе, и наша задача — найти перспективные кадры, которые смогут
				внести значительный вклад в развитие астрономической науки, стать будущими учёными, исследователями и
				популяризаторами космоса, – поделилась Антонина Дворкина-Самарская, кандидат физико-математических наук, доцент
				кафедры физики Педагогического института ИГУ.
			</p>
			<p class="NewsPage-sub-title">
				Участники, приехавшие на программу по математике, смогли углубить свои знания в работе с обыкновенными и
				десятичными дробями, научились возводить числа в степень и вычислять площадь многоугольников. Также они будут
				решали задачи по вероятности и статистике, что поможет им развить аналитическое мышление и математическую
				интуицию.
			</p>
			<p class="NewsPage-sub-title">
				Профильная смена «Байкальские звезды «Абилимпикса» для детей с ограниченными возможностями здоровья в третий раз
				прошла в Образовательном центре «Персей».
			</p>
			<p class="NewsPage-sub-title">
				Движение «Абилимпикс» является частью президентской платформы «Россия – страна возможностей» и направлено на
				создание условий для людей с инвалидностью, чтобы они могли проявить свои профессиональные навыки и получить
				возможности для успешного трудоустройства.
			</p>
			<p class="NewsPage-sub-title">
				Профильная смена позволила учащимся познакомиться с движением «Абилимпикс», развить свои профессиональные навыки
				и расширить знания в различных направлениях, которые пригодятся для успешного участия в одноименном Чемпионате.
				Участники смогли не только освоить новые компетенции, но и узнали о возможностях дальнейшего поступления в
				учебные заведения региона, в которых активно развивается движение.
			</p>
			<p class="NewsPage-sub-title">
				В этом году на смене были реализованы четыре модуля: «Экология», «Математика», «Робототехника» и «Искусственный
				интеллект». Каждый модуль позволил ребятам развить критическое мышление, навыки командной работы и творческий
				подход к решению сложных задач. Участники углубили свои знания по ключевым направлениям и научились эффективно
				применять их на практике. Занятия сопровождались мастер-классами от опытных специалистов, что дало ребятам
				возможность получить актуальные навыки, востребованные в современных профессиональных сферах.
			</p>
			<p class="NewsPage-sub-title">
				– Эти профильные смены – уникальная возможность для школьников Приангарья не только углубить свои знания, но и
				поработать с ведущими экспертами в данных областях и с уникальным оборудованием, которым оснащен Образовательный
				центр «Персей», реализующий свою деятельность в рамках реализации президентского национального проекта
				«Образование». Благодаря таким образовательным программам мы раскрываем таланты наших ребят. Это важный шаг на
				пути к развитию научного и интеллектуального потенциала региона, – отметил директор Образовательного центра
				«Персей» Алексей Шестаков.
			</p>
			<p class="NewsPage-sub-title">
				Напомним, смены прошли с 10 по 16 сентября. В образовательных программах приняли участие из Усть-Илимска,
				Киренска, Шелехова, Тулуна, Нижнеудинска, Ангарска, Братска, Саянска, Зимы, Усолье-Сибирского, а также
				Иркутского, Усольского, Боханского и Тайшетского районов.
			</p>

		</div>
	</div>
</template>
