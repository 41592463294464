<template>
	<div class="HowToGet _cover">
		<h2 class="HowToGet-title _cover-title">
			Условия заезда, размещения и выезда учащихся
		</h2>
		<a class="EventsPage-btn temp-btn"
			href="https://starsite.perseusirk.ru/wp-content/uploads/2023/11/%D0%9F%D0%90%D0%9C%D0%AF%D0%A2%D0%9A%D0%90-%D0%94%D0%9B%D0%AF-%D0%A0%D0%9E%D0%94%D0%98%D0%A2%D0%95%D0%9B%D0%95%D0%99-%D0%9E%D0%A6-%D0%9F%D0%95%D0%A0%D0%A1%D0%95%D0%99.pdf"
			target="_blank">
			Памятка для родителей
		</a>

		<hr>

		<h3 class="HowToGet-title _cover-title">
			Уважаемые родители и участники профильных смен!
		</h3>

		<ul class="HowToGet-list">
			<li class="HowToGet-items">
				Организация заезда учащихся на профильную смену осуществляется родителями (законными представителями)
				<b>САМОСТОЯТЕЛЬНО!!!</b> по адресу Образовательный центр «Персей» (Иркутская область, Ангарский район, 8,351 км.
				Автодороги Ангарск-Тальяны)
			</li>



			<li class="HowToGet-items">
				Заезд осуществляется <b>с 8:00 до 12:00</b> в первый день профильной смены (дополнительные вопросы решаются
				индивидуально с руководителем смены).
			</li>
			<li class="HowToGet-items">
				При заезде учащихся должен предоставить следующие документы:
				<ul class="HowToGet-list-dis">
					<li class="HowToGet-items-dis">
						<a href="https://starsite.perseusirk.ru/wp-content/uploads/2024/09/%D0%94%D0%BE%D0%B3%D0%BE%D0%B2%D0%BE%D1%80-%D0%BD%D0%B0-%D0%BF%D1%80%D0%BE%D1%84.-%D1%81%D0%BC%D0%B5%D0%BD%D1%83-%D0%94%D0%BC%D0%B8%D1%82%D1%80%D0%B8%D0%B5%D0%B2.pdf"
							target="_blank" download>
							<b>
								Договор об организации отдыха детей и молодежи проф. смену.
								<p style="color: blue; display: inline">Скачать.</p>
							</b>
						</a>
					</li>
					<li class="HowToGet-items-dis">
						<a href="https://starsite.perseusirk.ru/wp-content/uploads/2024/09/%D0%97%D0%B0%D1%8F%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5-%D0%94%D0%BC%D0%B8%D1%82%D1%80%D0%B8%D0%B5%D0%B2.pdf"
							target="_blank" download>
							<b>
								Заявление о зачислении на профильную смену.
								<p style="color: blue; display: inline">Скачать.</p>
							</b>
						</a>
					</li>
					<li class="HowToGet-items-dis">
						<a href="https://starsite.perseusirk.ru/wp-content/uploads/2023/03/%D1%81%D0%BE%D0%B3%D0%BB%D0%B0%D1%81%D0%B8%D0%B5-%D0%BD%D0%B0-%D0%B2%D0%B8%D0%B4%D1%8B-%D0%BC%D0%B5%D0%B4%D0%B8%D1%86%D0%B8%D0%BD%D1%81%D0%BA%D0%B8%D1%85-%D0%B2%D0%BC%D0%B5%D1%88%D0%B0%D1%82%D0%B5%D0%BB%D1%8C%D1%81%D1%82%D0%B2.pdf"
							target="_blank" download>
							<b>
								Согласие на виды медицинских вмешательств.
								<p style="color: blue; display: inline">Скачать.</p>
							</b>
						</a>
						<p>
							или
						</p>
						<a href="https://starsite.perseusirk.ru/wp-content/uploads/2023/03/%D0%9E%D1%82%D0%BA%D0%B0%D0%B7-%D0%BE%D1%82-%D0%B2%D0%B8%D0%B4%D0%BE%D0%B2-%D0%BC%D0%B5%D0%B4%D0%B8%D1%86%D0%B8%D0%BD%D1%81%D0%BA%D0%B8%D1%85-%D0%B2%D0%BC%D0%B5%D1%88%D0%B0%D1%82%D0%B5%D0%BB%D1%8C%D1%81%D1%82%D0%B2-1.pdf"
							target="_blank" download>
							<b>
								Отказ от видов медицинских вмешательств.
								<p style="color: blue; display: inline">Скачать.</p>
							</b>
						</a>
					</li>
					<li class="HowToGet-items-dis">
						<a href="https://starsite.perseusirk.ru/wp-content/uploads/2023/03/%D0%A1%D0%9E%D0%93%D0%9B%D0%90%D0%A1%D0%98%D0%95-%D0%9D%D0%90-%D0%9E%D0%91%D0%A0%D0%90%D0%91%D0%9E%D0%A2%D0%9A%D0%A3-%D0%9F%D0%95%D0%A0%D0%A1%D0%9E%D0%9D%D0%90%D0%9B%D0%AC%D0%9D%D0%AB%D0%A5-%D0%94%D0%90%D0%9D%D0%9D%D0%AB%D0%A5.pdf"
							target="_blank" download>
							<b>
								СОГЛАСИЕ НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ С 14 ЛЕТ.
								<p style="color: blue; display: inline">Скачать.</p>
							</b>
						</a>
						<p>
							или
						</p>
						<a href="https://starsite.perseusirk.ru/wp-content/uploads/2023/03/%D0%A1%D0%9E%D0%93%D0%9B%D0%90%D0%A1%D0%98%D0%95-%D0%9D%D0%90-%D0%9E%D0%91%D0%A0%D0%90%D0%91%D0%9E%D0%A2%D0%9A%D0%A3-%D0%9F%D0%95%D0%A0%D0%A1%D0%9E%D0%9D%D0%90%D0%9B%D0%AC%D0%9D%D0%AB%D0%A5-%D0%94%D0%90%D0%9D%D0%9D%D0%AB%D0%A5-%D0%94%D0%9E-14-%D0%9B%D0%95%D0%A2.pdf"
							target="_blank" download>
							<b>
								СОГЛАСИЕ НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ ДО 14 ЛЕТ.
								<p style="color: blue; display: inline">Скачать.</p>
							</b>
						</a>
					</li>
					<li class="HowToGet-items-dis">
						<a href="https://starsite.perseusirk.ru/wp-content/uploads/2023/08/%D0%A1%D0%BE%D0%B3%D0%BB%D0%B0%D1%81%D0%B8%D0%B5_%D1%84%D0%BE%D1%82%D0%BE_%D0%B2%D0%B8%D0%B4%D0%B5%D0%BE%D1%81%D1%8A%D0%B5%D0%BC%D0%BA%D1%83.docx"
							target="_blank" download>
							<b>
								Согласие на фото и видеосъемку в рамках съемки серии рекламных видеороликов ОЦ «Персей»
								<p style="color: blue; display: inline">Скачать.</p>
							</b>
						</a>
					</li>
					<li class="HowToGet-items-dis">
						копия документа, удостоверяющего личность Ребенка (св-во о рождении/ паспорт);
					</li>
					<li class="HowToGet-items-dis">
						копия СНИЛС Ребенка;
					</li>
					<li class="HowToGet-items-dis">
						копия полиса обязательного медицинского страхования Ребенка (с 2-х сторон);
					</li>
					<li class="HowToGet-items-dis">
						медицинская справка о состоянии здоровья Ребенка формы 079/у (с обязательным указанием профилактических
						прививок и анализов: общий анализ крови, мочи, кал на я/глист и энтеробиоз);
					</li>
					<li class="HowToGet-items-dis">
						справка об отсутствии контакта с инфекционными больными;
					</li>
					<li class="HowToGet-items-dis">
						страховой полис на случай укуса клеща или сертификат о прививке от клещевого энцефалита (на период
						апрель-сентябрь);
					</li>
					<li class="HowToGet-items-dis">
						страховой полис от несчастного случая;
					</li>
				</ul>
			</li>
			<li class="HowToGet-items">
				Выезд учащихся организуется также самостоятельно родителями (законными представителями) в последний день
				профильной смены <b>до 12:00</b> или накануне вечером <b>после 19:30 до 21:00 или до 22:00, по согласованию!</b>
			</li>
		</ul>
	</div>
</template>